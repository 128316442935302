<template>
  <div id="app" class="fullscreen">
    <router-view></router-view>
  </div>
</template>

<script>
// import googleOneTap from "google-one-tap";
// import axios from "axios";
import { mapGetters, mapActions } from "vuex";

export default {
  name: "App",

  metaInfo: {
    // if no subcomponents specify a metaInfo.title, this title will be used
    title: "Home",
    // all titles will be injected into this template
    titleTemplate: "%s | Boost Art Net",
  },

  computed: {
    ...mapGetters({
      shrinkLeftMenu: "getShrinkLeftMenu",
      modalVisible: "getModalVisible",
      isFullscreen: "getFullscreen",
    }),
  },
  methods: {
    ...mapActions(["updateHardwareBackActive", "toggleFullscreen"]),
  },

  mounted() {
    // const options = {
    //   client_id:
    //     "484307503869-5o28j7mfmifu1acpik6a79tltt8qotpp.apps.googleusercontent.com", // required
    //   auto_select: false, // optional
    //   cancel_on_tap_outside: true, // optional
    //   context: "signin", // optional
    // };
    const isMobile = window.innerWidth <= 450;
    const ipad = Boolean(
      !!navigator.userAgent.match(/(iPad).*OS\s([\d_]+)/) || // browser devtools ipad
        (/Macintosh/i.test(navigator.userAgent) && // real ipad devices
          navigator.maxTouchPoints &&
          navigator.maxTouchPoints > 1)
    );
    if (!this.$cookie.get("boost-art-net-token") && !isMobile && !ipad) {
      // googleOneTap(options, async (response) => {
      //   // Send response to server
      //   const { credential } = response;
      //   this.$cookie.set("boost-art-net-token", credential, { expires: 7 });
      //   const res = await axios({
      //     method: "POST",
      //     url: "https://api.boost-art.net/v1/login",
      //     data: {
      //       "boost-art-net-token": credential,
      //     },
      //     withCredentials: true,
      //   });
      //   console.log("message: ", res["data"]["message"]);
      // });
    }

    // Listen to phone hardware-back event to determine
    // whether to close the image detail modal or not.
    window.addEventListener("message", (event) => {
      const message = event?.data;
      if (message === "hardware-back") {
        // Ensure the supposed navigation stack is not empty
        // if empty, directly exit the mobile app.
        if (this.shrinkLeftMenu && !this.modalVisible) {
          window.parent.postMessage("empty-stack", "*");
        } else {
          this.updateHardwareBackActive(true);
          setTimeout(() => {
            this.updateHardwareBackActive(false);
          }, 500);
        }
      }
    });

    // Detect FullScreen Event Change
    document.addEventListener("fullscreenchange", () => {
      this.toggleFullscreen();
      // To make sure fullscrren getter at HomePage.vue responds right.
      this.$forceUpdate();
      const that = this;
      this.$nextTick(() => {
        console.log(that.isFullscreen);
      });
    });
  },
};
</script>

<style lang="less">
@media screen and (min-width: 425px) {
  /*- scrollbar -*/
  ::-webkit-scrollbar {
    width: 10px;
  }

  ::-webkit-scrollbar-thumb {
    background-color: #999;
    -webkit-border-radius: 10px;
    border-radius: 10px;
  }

  ::-webkit-scrollbar-thumb:vertical:hover {
    background-color: #666;
  }

  ::-webkit-scrollbar-thumb:vertical:active {
    background-color: #333;
  }

  ::-webkit-scrollbar-button {
    display: none;
  }

  ::-webkit-scrollbar-track {
    background-color: #f1f1f1;
  }

  /*- scrollbar -*/
}
// iphone ios hair screen
@supports (
    (height: constant(safe-area-inset-top)) or
      (height: env(safe-area-inset-top))
  )
  and (-webkit-overflow-scrolling: touch) {
  .fullscreen {
    /* hair screen */
    padding-top: 20px;
    padding-top: constant(safe-area-inset-top);
    padding-top: env(safe-area-inset-top);

    /* bottom black bar */
    padding-bottom: 0;
    padding-bottom: costant(safe-area-inset-bottom);
    padding-bottom: env(safe-area-inset-bottom);
  }
}
@supports (-webkit-overflow-scrolling: touch) {
  /* non-hair screen ios*/
}
</style>
