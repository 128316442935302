<template>
  <div>
    <transition name="fade-in">
      <!-- 第一次打开后才渲染dom -->
      <div
        v-if="isFirstShow"
        v-show="finallyVisible"
        ref="imagePreview"
        class="preview-container"
        :id="previewContainerHashTag"
      >
        <div class="image-wrapper">
          <slot name="loading" :loading="loading">
            <Snippet v-if="loading" />
          </slot>
          <!-- draggable: false 禁止 chrome 拖拽图片 -->
          <div
            :style="imgStyle"
            :class="['image-container', isMobile ? 'mobile' : '']"
          >
            <img
              v-show="!loading"
              ref="image"
              :width="mobileWidth"
              :src="finallyImageList[currentPosition]"
              :alt="`Image ${currentPosition + 1}`"
              class="image"
              draggable="false"
              @load="handleImageLoad"
              @error="hideLoading"
              @abort="hideLoading"
              @mousedown="handleImageMouseDown"
              @wheel="wheelScale"
            />
            <template v-if="showOperateArea">
              <div
                class="icon-heart"
                :style="isMobile && showOperateArea ? 'display: flex' : ''"
                @click.stop="
                  () => {
                    likeAction('dislike');
                  }
                "
                v-if="liked"
              >
                <SvgIcon class="icon" name="heart_full" />
              </div>
              <div
                class="icon-heart"
                :style="isMobile && showOperateArea ? 'display: flex' : ''"
                @click.stop="
                  () => {
                    likeAction('like');
                  }
                "
                v-else
              >
                <SvgIcon class="icon" name="heart_empty" />
              </div>
            </template>
          </div>
        </div>
        <div class="pos-tip" v-if="finallyImageList.length > 1">
          {{ currentPosition + 1 }} / {{ totalCount }}
        </div>
        <div class="close hover-icon" @click="close" v-if="showClose">
          <SvgIcon class="icon" name="guanbi" />
        </div>
        <div
          class="arrow arrow-prev hover-icon"
          @click="updatePosition(-1)"
          v-if="finallyImageList.length > 1"
        >
          <SvgIcon class="icon" name="shangyizhang" />
        </div>
        <div
          class="arrow arrow-next hover-icon"
          @click="updatePosition(1)"
          v-if="finallyImageList.length > 1"
        >
          <SvgIcon class="icon" name="xiayizhang" />
        </div>
        <div class="operate-area" v-if="showOperateArea">
          <slot name="operate">
            <template v-if="!isMobile && !isIpad">
              <SvgIcon
                class="icon hover-icon"
                name="fullscreen"
                @click="enterFullScreen"
              />
              <div class="divide" />
            </template>
            <SvgIcon
              class="icon hover-icon"
              name="actionicon"
              @click="increaseScale"
            />
            <SvgIcon
              class="icon hover-icon"
              name="suoxiao"
              @click="decreaseScale"
            />
            <div class="divide" />
            <SvgIcon
              class="icon hover-icon"
              name="xuanzhuan-r"
              @click="rightRotate"
            />
            <div class="divide" />
            <template v-if="isMobile || isIpad">
              <SvgIcon
                class="icon hover-icon"
                name="zhongzhi"
                @click="onResetClick"
              />
            </template>
            <template v-if="!isMobile && !isIpad">
              <SvgIcon
                class="icon hover-icon"
                name="googlesearch"
                title="Google Images Search"
                @click="onGoogleImageSearch"
              />
            </template>
          </slot>
          <slot name="extraOperate">
            <div class="divide" />
            <SvgIcon
              class="iconFont icon hover-icon"
              name="share"
              @click="mobileNativeShareClick"
              v-if="mobileNativeShare"
            />
            <van-popover
              v-else
              v-model="showSharePopover"
              trigger="click"
              placement="top"
              theme="dark"
              :get-container="`#${previewContainerHashTag}`"
            >
              <ShareNetwork
                network="pinterest"
                :url="metaInfo.url"
                :title="metaInfo.title"
                :description="metaInfo.description"
                :hashtags="metaInfo.hashtags"
                :media="metaInfo.media"
              >
                <div>
                  <i
                    class="van-popover-share-network iconFont fab fa-pinterest"
                  ></i>
                </div>
              </ShareNetwork>
              <ShareNetwork
                network="twitter"
                :url="metaInfo.url"
                :title="metaInfo.title"
                :description="metaInfo.description"
                :hashtags="metaInfo.hashtags"
              >
                <div>
                  <i
                    class="van-popover-share-network iconFont fab fa-twitter"
                  ></i>
                </div>
              </ShareNetwork>
              <ShareNetwork
                network="facebook"
                :url="metaInfo.url"
                :title="metaInfo.title"
                :description="metaInfo.description"
                :hashtags="metaInfo.hashtags"
              >
                <div>
                  <i
                    class="van-popover-share-network iconFont fab fa-facebook"
                  ></i>
                </div>
              </ShareNetwork>
              <ShareNetwork
                network="linkedin"
                :url="metaInfo.url"
                :title="metaInfo.title"
                :description="metaInfo.description"
                :hashtags="metaInfo.hashtags"
              >
                <div>
                  <i
                    class="van-popover-share-network iconFont fab fa-linkedin"
                  ></i>
                </div>
              </ShareNetwork>
              <template #reference>
                <SvgIcon class="iconFont icon hover-icon" name="share" />
              </template>
            </van-popover>
          </slot>
        </div>
        <transition name="fade">
          <div :key="scale" :style="scaleTipStyle" class="scale-tip">
            {{ ~~(scale * 100) }}%
          </div>
        </transition>
      </div>
    </transition>
    <div v-if="isSlotMode" ref="slotWrapper" @click="handleImgWrapperClick">
      <slot />
    </div>
  </div>
</template>

<script>
import { debounce } from "lodash";
import Snippet from "./Snippet";
import SvgIcon from "./SvgIcon";

import { forbiddenBodyScroll, ALERT, validateNumber } from "./util";

const DEFAULT_MAX_SCALE = 5; // 最大放大比例
const DEFAULT_MIN_SCALE = 0.1; // 最小放大比例
const DEFAULT_STEP = 0.1; // 单次缩放变化的比例
const DEFAULT_ANGLE = 90;
const DEFAULT_LOADING_DELAY = 300; // 默认 loading 延迟时间 (ms)
const BASE_SELECTOR = "img"; // 默认选择器
const DEFAULT_FILTER_FUNCTION = () => true; // 插槽模式下，默认过滤函数

export default {
  name: "ImagePreview",
  components: {
    Snippet,
    SvgIcon,
  },
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    // SEO元信息
    metaInfo: {
      type: Object,
      default: () => ({}),
    },
    // 图片地址
    imageUrls: {
      type: Array,
      default: () => [],
    },
    urlMapper: {
      type: Function,
      default: null,
    },
    // 起始位置
    startPosition: {
      type: Number,
      default: 0,
    },
    maxScale: {
      type: [String, Number],
      default: DEFAULT_MAX_SCALE,
      validator: validateNumber("maxScale"),
    },
    minScale: {
      type: [String, Number],
      default: DEFAULT_MIN_SCALE,
      validator: validateNumber("minScale"),
    },
    scaleStep: {
      type: [String, Number],
      validator: validateNumber("scaleStep"),
      default: DEFAULT_STEP,
    },
    angle: {
      type: [String, Number],
      validator: validateNumber("angle"),
      default: DEFAULT_ANGLE,
    },
    includeSelector: {
      type: String,
      default: "",
    },
    excludeSelector: {
      type: String,
      default: "",
    },
    filter: {
      type: Function,
      default: DEFAULT_FILTER_FUNCTION,
    },
    closeOnPressEscape: {
      type: Boolean,
      default: true,
    },
    loadingDelay: {
      type: [String, Number],
      default: DEFAULT_LOADING_DELAY,
      validator: validateNumber("loadingDelay"),
    },
    isMobile: {
      type: Boolean,
      default: false,
    },
    isIpad: {
      type: Boolean,
      default: false,
    },
    liked: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isFirstShow: false,
      currentPosition: 0,
      slotModeVisible: false,
      loading: false,
      imgList: [],
      urlList: [],
      scale: 1,
      rotateAngle: 0,
      aspectRatio: 1,
      position: {
        left: 0,
        top: 0,
      },
      mouse: {
        x: 0,
        y: 0,
      },
      documentEvtFunc: null,
      showClose: true,
      showOperateArea: true,
      showSharePopover: false,
    };
  },
  computed: {
    previewContainerHashTag() {
      // Issue #249
      // ["https://westartbucket101.blob.core.windows.net/2021/Bruegel019.png"]
      // to
      // "preview-container-Bruegel019"
      const key = this.imageUrls[0].split("/").at(-1).split(".")[0];
      return `preview-container-${key}`;
    },
    mobileWidth() {
      // Fit the mobile screen size when entering preview
      return this.isMobile ? window.innerWidth - 10 : "unset";
    },
    isSlotMode() {
      return !!this.$slots.default;
    },
    innerMaxScale() {
      let maxScale = +this.maxScale;
      return Number.isFinite(maxScale) && maxScale >= 1
        ? maxScale
        : DEFAULT_MAX_SCALE;
    },
    innerMinScale() {
      let minScale = +this.minScale;
      return Number.isFinite(minScale) && minScale <= 1 && minScale > 0
        ? minScale
        : DEFAULT_MIN_SCALE;
    },
    innerScaleStep() {
      let scaleStep = +this.scaleStep;
      return Number.isFinite(scaleStep) ? scaleStep : DEFAULT_STEP;
    },
    innerAngle() {
      let angle = +this.angle;
      return Number.isFinite(angle) ? angle : DEFAULT_ANGLE;
    },
    // 根据不同用法生成图片列表
    finallyImageList() {
      return this.isSlotMode
        ? this.urlMapper
          ? this.urlList.map(this.urlMapper)
          : this.urlList
        : this.imageUrls;
    },
    finallyVisible() {
      return this.isSlotMode ? this.slotModeVisible : this.visible;
    },
    totalCount() {
      return (this.finallyImageList || []).length;
    },
    imgStyle() {
      let { left, top } = this.position;
      let styleKey;
      if (!this.isMobile) {
        styleKey = this.aspectRatio > 1 ? "max-width" : "max-height";
      } else {
        styleKey = "";
      }
      return {
        transform: `translate3d(${left}px, ${top}px, 0) scale(${this.scale}) rotate(${this.rotateAngle}deg)`,
        [styleKey]: "100%",
      };
    },
    scaleTipStyle() {
      let { left, top } = this.position;
      return `transform: translate3d(calc(-50% + ${left}px), calc(-50% + ${top}px), 0)`;
    },
    mobileNativeShare() {
      const userAgent = window.navigator.userAgent;
      const ipad =
        !!userAgent.match(/(iPad).*OS\s([\d_]+)/) || // browser devtools ipad
        (/Macintosh/i.test(userAgent) && // real ipad devices
          navigator.maxTouchPoints &&
          navigator.maxTouchPoints > 1);
      const iphone = userAgent.match(/(iPhone\sOS)\s([\d_]+)/);
      const android = Boolean(
        userAgent.match(/Android/i) && userAgent.match(/Mobile/i)
      );
      return ipad || iphone || android;
    },
  },
  watch: {
    visible: {
      immediate: true,
      handler: "handleVisible",
    },
    slotModeVisible: "handleVisible",
    startPosition: function (val) {
      this.currentPosition = val;
    },
    // 切换图片 src 时触发
    currentPosition: "handleImageSourceChange",
    finallyImageList: "handleImageSourceChange",

    closeOnPressEscape: {
      immediate: true,
      handler(val, oldVal) {
        if (val) {
          window.addEventListener("keyup", this.handlePressESC);
        }
        // 由 true -> false， 将之前事件解绑掉
        if (oldVal && !val) {
          window.removeEventListener("keyup", this.handlePressESC);
        }
      },
    },
  },

  mounted() {
    if (this.isMobile || window.innerWidth <= 450) {
      this.showClose = false;
      this.showOperateArea = false;
    }
    if (this.isSlotMode) {
      this.imgList = this.queryImgList();
      this.initImgList();
    }
  },
  beforeDestroy() {
    this.closeOnPressEscape &&
      window.removeEventListener("keyup", this.handlePressESC);
  },
  // 插槽子元素变化时，重新初始化
  updated() {
    if (this.isSlotMode) {
      let newImgList = this.queryImgList();
      let equal = false;
      if (this.imgList.length === newImgList.length && this.imgList.length) {
        equal = Array.from(this.imgList).every(
          (img, index) => img === newImgList[index]
        );
      }

      if (!equal) {
        this.imgList = newImgList;
        this.initImgList();
      }
    }
  },
  methods: {
    // ==================================== 对外方法 Start =============================================
    rotate(angle) {
      if (typeof angle === "function") {
        angle = +angle(this.rotateAngle);
      } else {
        angle = +angle;
      }
      if (Number.isFinite(angle)) {
        this.rotateAngle = angle;
      } else {
        ALERT(
          "rotate 方法参数必须为一个数字或函数(如果是函数，则该函数的返回值必须为数字)"
        );
      }
    },
    zoom(zoomRate) {
      if (typeof zoomRate === "function") {
        zoomRate = +zoomRate(this.scale);
      } else {
        zoomRate = +zoomRate;
      }

      if (Number.isFinite(zoomRate)) {
        // 限制缩放范围在“设定范围之内”
        if (zoomRate < this.innerMinScale) {
          this.scale = this.innerMinScale;
        } else if (zoomRate > this.innerMaxScale) {
          this.scale = this.innerMaxScale;
        } else {
          this.scale = zoomRate;
        }
        // console.error(`zoom传入的参数(如果是函数，则为函数的返回值)超过设定的缩放范围，该范围为${this.minScale}~${this.maxScale}`)
      } else {
        ALERT(
          "zoom 方法参数必须为一个数字或函数(如果是函数，则该函数的返回值必须为数字)"
        );
      }
    },
    reset() {
      return this.resetImage();
    },
    // ==================================== 对外方法 End =============================================
    /**
     * 根据传入的css选择器筛选，生成最终的选择器
     * 举个例子
     * includeSelector: ".img1, img2"
     * excludeSelector: ".img3, .img4"
     * => ".img1:not(.img3):not(.img4), .img2:not(.img3):not(.img4)"
     */
    parseSelector() {
      const SPLIT_REG = /\s*,\s*/g;
      let includeSelectorList = [];
      let excludeSelectorList = [];
      // 处理传入的css选择器的逗号
      if (this.includeSelector && typeof this.includeSelector === "string") {
        includeSelectorList = this.includeSelector.split(SPLIT_REG);
      }
      if (this.excludeSelector && typeof this.excludeSelector === "string") {
        excludeSelectorList = this.excludeSelector.split(SPLIT_REG);
      }
      let selectorList = includeSelectorList.map((selector) => {
        let filterSelector = excludeSelectorList.map(
          (exSelector) => `:not(${exSelector})`
        );
        return BASE_SELECTOR + selector + filterSelector.join("");
      });
      return selectorList.join(", ") || BASE_SELECTOR;
    },
    queryImgList() {
      let selector =
        this.filter === DEFAULT_FILTER_FUNCTION
          ? this.parseSelector()
          : BASE_SELECTOR;
      let imgList = this.$refs.slotWrapper.querySelectorAll(selector);
      return Array.from(imgList).filter(this.filter);
    },
    initImgList() {
      this.imgList.forEach((img) => {
        img.style.cursor = "zoom-in";
        this.urlList.push(img.src);
      });
    },
    handleImgWrapperClick(e) {
      if (e.target.tagName === "IMG") {
        let index = Array.from(this.imgList).findIndex(
          (img) => img === e.target
        );

        if (index < 0) return;

        this.slotModeVisible = true;
        this.currentPosition = index;
        this.resetImage();
      }
    },
    resetImage(fade = "") {
      if (fade === "fade") {
        this.$refs.imagePreview.style.opacity = "0";
        setTimeout(() => {
          this.$refs.imagePreview.style.opacity = "unset";
          this.scale = 1;
          this.rotateAngle = 0;
          this.position = {
            left: 0,
            top: 0,
          };
        }, 300);
      } else {
        this.scale = 1;
        this.rotateAngle = 0;
        this.position = {
          left: 0,
          top: 0,
        };
      }
    },
    handleVisible(visible) {
      if (visible) {
        if (!this.isFirstShow) {
          this.handleFirstVisible();
        }
        this.restoreBody = forbiddenBodyScroll();

        !this.documentEvtFunc && this.registerKeyboardEvent();
      } else {
        this.restoreBody && this.restoreBody();
        this.$nextTick(() => {
          this.currentPosition = this.startPosition;
        });

        this.terminateKeyboardEvent();
        // Set operate area to hidden when close
        if (this.isMobile || window.innerWidth <= 450) {
          this.showClose = false;
          this.showOperateArea = false;
        }
      }
    },
    registerKeyboardEvent() {
      this.documentEvtFunc = (event) => {
        const key = event.key.toLowerCase();

        if (key === "control") {
          this.close();
        } else if (key === "arrowup") {
          this.increaseScale();
        } else if (key === "arrowdown") {
          this.decreaseScale();
        }
      };
      document.addEventListener("keydown", this.documentEvtFunc);
    },
    terminateKeyboardEvent() {
      document.removeEventListener("keydown", this.documentEvtFunc);
      this.documentEvtFunc = null;
    },
    handleFirstVisible() {
      // dom渲染后，将其插入body中
      this.isFirstShow = true;
      this.$nextTick(() => {
        // 避免 fixed 被祖先元素的 transform 等属性影响定位
        // refer: https://github.com/chokcoco/iCSS/issues/24
        document.body.appendChild(this.$refs.imagePreview);

        // loading
        this.handleImageSourceChange();
      });
    },
    initAspectRatio(e) {
      let width = e.target.offsetWidth;
      let height = e.target.offsetHeight;
      this.aspectRatio = width / height;
    },
    handleImageLoad(e) {
      this.initAspectRatio(e);
      this.hideLoading();
      this.$emit("imageLoad");
    },
    updatePosition(next) {
      const _next = this.currentPosition + next;
      if (_next >= this.finallyImageList.length) {
        this.currentPosition = 0;
      } else if (_next < 0) {
        this.currentPosition = this.finallyImageList.length - 1;
      } else {
        this.currentPosition = _next;
      }
      this.resetImage();
    },
    handleImageSourceChange() {
      // 等待 dom 渲染之后再取 complete 属性
      this.$nextTick(() => {
        // 加载未缓存图片时，开启 loading
        if (this.$refs.image && !this.$refs.image.complete) {
          this.showLoading();
        }
      });
    },
    showLoading() {
      clearTimeout(this.loadingTimer);
      this.loadingTimer = setTimeout(() => {
        this.loading = true;
      }, this.loadingDelay);
    },
    hideLoading() {
      clearTimeout(this.loadingTimer);
      this.loading = false;
    },
    leftRotate() {
      if (!this.loading) {
        this.rotateAngle -= this.innerAngle;
      }
    },
    rightRotate() {
      if (!this.loading) {
        this.rotateAngle += this.innerAngle;
      }
    },
    increaseScale() {
      !this.loading &&
        this.zoom((scale) => (scale + this.innerScaleStep).toFixed(1)); // 处理精度丢失
    },
    decreaseScale() {
      !this.loading &&
        this.zoom((scale) => (scale - this.innerScaleStep).toFixed(1)); // 处理精度丢失
    },
    onResetClick() {
      !this.loading && this.reset();
    },
    onGoogleImageSearch() {
      window.open(
        `https://lens.google.com/uploadbyurl?url=${encodeURIComponent(
          this.finallyImageList[this.currentPosition]
        )}&hl=en`,
        "_blank"
      );
    },
    enterFullScreen() {
      if (this.isFullScreenCurrently()) {
        this.exitFullScreen();
        console.log("fullscreen false");
      } else {
        if (document.body.requestFullscreen) {
          document.body.requestFullscreen();
        } else if (document.body.mozRequestFullScreen) {
          document.body.mozRequestFullScreen();
        } else if (document.body.webkitRequestFullscreen) {
          document.body.webkitRequestFullscreen();
        } else if (document.body.msRequestFullscreen) {
          document.body.msRequestFullscreen();
        }
        console.log("fullscreen true");
      }
    },
    exitFullScreen() {
      if (document.exitFullscreen) {
        document.exitFullscreen();
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      } else if (document.webkitExitFullscreen) {
        document.webkitExitFullscreen();
      } else if (document.msExitFullscreen) {
        document.msExitFullscreen();
      }
    },
    isFullScreenCurrently() {
      const full_screen_element =
        document.fullscreenElement ||
        document.webkitFullscreenElement ||
        document.mozFullScreenElement ||
        document.msFullscreenElement ||
        null;
      // If no element is in full-screen
      if (full_screen_element === null) return false;
      else return true;
    },
    // 图片拽拉
    handleImageMouseDown(e) {
      if (this.isMobile) {
        this.showClose = !this.showClose;
        this.showOperateArea = !this.showOperateArea;
        return;
      }
      this.mouse = {
        x: e.clientX,
        y: e.clientY,
      };
      window.addEventListener("mousemove", this.handleImageMouseMove);
      window.addEventListener("mouseup", this.handleImageMouseUp);
    },
    handleImageMouseMove(e) {
      // 移动event的坐标
      let { clientX, clientY } = e;
      // 鼠标按下时记录的坐标
      let { x, y } = this.mouse;
      // 偏移后的位置
      let deltaX = clientX - x + this.position.left;
      let deltaY = clientY - y + this.position.top;
      this.mouse = {
        x: clientX,
        y: clientY,
      };
      this.position = {
        left: deltaX,
        top: deltaY,
      };
    },
    handleImageMouseUp(e) {
      try {
        window.removeEventListener("mousemove", this.handleImageMouseMove);
        window.removeEventListener("mouseup", this.handleImageMouseUp);
      } catch {
        console.log(e);
      }
    },
    // 滚轮缩放
    wheelScale: debounce(function (e) {
      if (e.deltaY <= 0) {
        !this.loading &&
          this.zoom((scale) => (scale + this.innerScaleStep).toFixed(1)); // 处理精度丢失
      } else {
        !this.loading &&
          this.zoom((scale) => (scale - this.innerScaleStep).toFixed(1)); // 处理精度丢失
      }
    }, 25),
    close() {
      console.log("close");
      if (this.isSlotMode) {
        this.slotModeVisible = false;
      } else {
        this.$emit("update:visible", false);
      }
      this.resetImage("fade");
    },
    handlePressESC(e) {
      let { keyCode, code } = e;
      if (this.finallyVisible && (keyCode === 27 || code === "Escape")) {
        this.close();
      }
    },
    likeAction(e) {
      if (e === "like") {
        this.$emit("like", true);
      } else {
        this.$emit("like", false);
      }
    },
    mobileNativeShareClick() {
      console.log("mobileNativeShareClick");
      const metaInfo = {
        url: this.metaInfo.url,
        title: this.metaInfo.title,
        description: this.metaInfo.description,
      };
      window.parent.postMessage(JSON.stringify(metaInfo), "*");
    },
  },
};
</script>

<style lang="less" scoped>
@import "./index.less";
.iconFont {
  // Trick to fetch the brands icon woff2 file in advance
  font-family: "Font Awesome 5 Brands";
}
</style>

<style lang="less">
.preview-container {
  .van-popover[data-popper-placement^="top"] .van-popover__arrow {
    bottom: 1px;
    z-index: -1;
  }
  .van-popover-share-network {
    &.iconFont {
      display: inline-block;
      text-align: center;
      vertical-align: middle;
      font-size: 16px;
      transition: all 0.25s ease 0s;
      border: none;
      outline: none;
      user-select: none;
      width: 48px;
      height: 48px;
      line-height: 48px;
      font-size: 16px;
      font-family: "Font Awesome 5 Brands";
      color: rgb(255, 255, 255);
      cursor: default;
      flex: 0 0 auto;

      &:hover {
        background: rgba(255, 255, 255, 0.2);
      }

      &:active {
        background: rgba(255, 255, 255, 0.4);
      }
    }
  }
}
</style>
